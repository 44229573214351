var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Modal",
    {
      attrs: {
        closable: true,
        visible: _vm.visible,
        size: "normal",
        "mask-closable": false,
        title: "基本信息设置",
        "ok-text": "确认",
      },
      on: { ok: _vm.handleOk, cancel: _vm.handleCancel },
    },
    [
      _c("div", { staticClass: "setting-modal-panel" }, [
        _c(
          "div",
          { staticClass: "setting-panel" },
          [
            _c("div", { staticClass: "setting-panel-title" }, [
              _vm._v("管理系统显示"),
            ]),
            _c("a-switch", {
              attrs: { checked: _vm.form.manageShow, size: "small" },
              on: {
                click: function (checked) {
                  return (_vm.form.manageShow = checked)
                },
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "setting-panel" },
          [
            _c("div", { staticClass: "setting-panel-title" }, [
              _vm._v("员工端显示"),
            ]),
            _c("a-switch", {
              attrs: { checked: _vm.form.staffShow, size: "small" },
              on: {
                click: function (checked) {
                  return (_vm.form.staffShow = checked)
                },
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "setting-panel" },
          [
            _c("div", { staticClass: "setting-panel-title" }, [
              _vm._v("入职登记表显示"),
            ]),
            _c("a-switch", {
              attrs: { size: "small", checked: _vm.form.entryFormShow },
              on: {
                click: function (checked) {
                  return (_vm.form.entryFormShow = checked)
                },
              },
            }),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }